<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="plans"
      :items-per-page="-1"
      dense
      hide-default-footer
      disable-sort
      @click:row="handleOpenPlanForm($event.id)"
    />
    <PlanForm @store="select()" ref="PlanForm" />
  </div>
</template>

<script>
import PlanForm from "@/components/subscription/forms/PlanForm.vue";
export default {
  components: {
    PlanForm,
  },
  data() {
    return {
      headers: [
        // {
        //   text: "Dessert (100g serving)",
        //   align: "start",

        //   value: "name",
        // },
        { text: "id", value: "id" },
        // { text: "external_id", value: "external_id" },
        { text: "vendor", value: "vendor" },
        { text: "name", value: "name" },
        // { text: "description", value: "description" },
        { text: "value", value: "value" },
        { text: "installments", value: "installments" },
        { text: "interval", value: "interval" },
        { text: "interval_count", value: "interval_count" },
        { text: "days", value: "days" },
        // { text: "charges", value: "charges" },
        { text: "type", value: "type" },
        // { text: "professional_limit", value: "professional_limit" },
        // { text: "attendant_limit", value: "attendant_limit" },
        { text: "status", value: "status" },
      ],
      // items:
      plans: [],
    };
  },

  mounted() {
    this.select();
  },

  methods: {
    select() {
      this.$http.index("subscription/plans").then((response) => {
        this.plans = response.plans;
      });
    },
    handleOpenPlanForm(id) {
      this.$refs.PlanForm.open(id);
    },
  },
};
</script>

<style>
</style>