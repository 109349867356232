<template>
  <v-dialog v-model="dialog" persistent width="800">
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span> Webhook Pix </span>

        <v-btn @click="handleConfigPixWebhook()" color="success">
          Gerar Webhook
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div class="code">
          <code v-text="webhooks" />
        </div>
      </v-card-text>

      <v-card-actions>
        <app-delete-btn v-if="hasWebhooks" @click="handleDeletePixWebhook()" />
        <v-spacer />
        <app-btn outlined rounded @click="dialog = false"> Voltar </app-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      webhooks: [],
    };
  },

  computed: {
    hasWebhooks() {
      return this.webhooks.length > 0;
    },
  },

  methods: {
    open() {
      this.select();

      this.dialog = true;
    },

    async select(page) {
      this.$loading.start();
      await this.$http
        .index("subscription/admin/pix-webhook-config")
        .then((response) => {
          this.webhooks = JSON.parse(JSON.stringify(response.webhooks));

          this.$loading.finish();
        })
        .catch((error) => this.$loading.finish());
    },

    handleConfigPixWebhook() {
      this.$loading.start();
      this.$http
        .store("subscription/admin/pix-webhook-config")
        .then((response) => {
          this.select();
          this.$loading.finish();
        })
        .catch((error) => this.$loading.finish());
    },
    handleDeletePixWebhook() {
      this.$loading.start();
      this.$http
        .destroy(
          "subscription/admin/pix-webhook-config",
          this.webhooks[0].chave
        )
        .then((response) => {
          this.select();
          this.$loading.finish();
        })
        .catch((error) => this.$loading.finish());
    },
  },
};
</script>

<style lang="scss" scoped>
.code {
  background-color: #282a36;
  code {
    white-space: pre-wrap;
    word-wrap: break-word;
    color: #f8f8f2;
    font-family: courier, monospace;
  }
}
</style>
