<template>
  <v-dialog v-model="dialog" scrollable max-width="500px">
    <v-card>
      <v-card-title> {{ title }} </v-card-title>
      <v-card-text>
        <br />
        <v-row>
          <v-col cols="6">
            <app-text-field v-model="form.external_id" label="external_id" />
          </v-col>
          <v-col cols="6">
            <app-text-field v-model="form.vendor" label="vendor" />
          </v-col>
          <v-col cols="6">
            <app-text-field v-model="form.name" label="name" />
          </v-col>
          <v-col cols="6">
            <app-text-field v-model="form.description" label="description" />
          </v-col>
          <v-col cols="6">
            <app-number-field v-model="form.value" label="value" />
          </v-col>
          <v-col cols="6">
            <app-text-field v-model="form.installments" label="installments" />
          </v-col>
          <v-col cols="6">
            <app-select
              v-model="form.interval"
              :items="intervals"
              label="interval"
            />
          </v-col>
          <v-col cols="6">
            <app-text-field
              v-model="form.interval_count"
              label="interval_count"
            />
          </v-col>
          <v-col cols="6">
            <app-text-field v-model="form.days" label="days" />
          </v-col>
          <v-col cols="6">
            <app-text-field v-model="form.charges" label="charges" />
          </v-col>
          <v-col cols="6">
            <app-select v-model="form.type" :items="types" label="type" />
          </v-col>
          <v-col cols="6">
            <app-text-field
              v-model="form.professional_limit"
              label="professional_limit"
            />
          </v-col>
          <v-col cols="6">
            <app-text-field
              v-model="form.attendant_limit"
              label="attendant_limit"
            />
          </v-col>
          <v-col cols="6">
            <v-switch
              v-model="form.status"
              label="Ativo"
              :true-value="'active'"
              :false-value="'inactive'"
            />
            <!-- <app-text-field v-model="form.status" label="status" /> -->
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <app-back-btn @click="dialog = false" />
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
        <app-confirm-btn @click="handleSave()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,

      form: {},

      types: [
        { text: "individual", value: "individual" },
        { text: "clinic", value: "clinic" },
      ],
      intervals: [
        { text: "day", value: "day" },
        // { text: "week", value: "week" },
        { text: "month", value: "month" },
        { text: "year", value: "year" },
      ],

      baseForm: {
        id: null,
        external_id: null,
        vendor: null,
        name: null,
        description: null,
        value: null,
        installments: null,
        interval: null,
        interval_count: null,
        days: null,
        charges: null,
        type: null,
        professional_limit: null,
        attendant_limit: null,
        status: "active",
      },
    };
  },

  computed: {
    isInsert() {
      return !this.form.id;
    },

    title() {
      return this.isInsert ? "Novo Plano" : "Editar Plano";
    },
  },

  methods: {
    open(id) {
      this.reset();

      if (id) {
        this.show(id);
      }

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    show(id) {
      this.$http.show("subscription/plans", id).then((response) => {
        this.form = response.plan;
      });
    },

    handleSave() {
      this.$loading.start();
      if (this.isInsert) {
        this.store();
      } else {
        this.update();
      }
    },

    async store() {
      await this.$http
        .store("subscription/admin/plans", this.form)
        .then((response) => this.processSaved())
        .catch((error) => this.$loading.finish());
    },

    async update() {
      await this.$http
        .update("subscription/admin/plans", this.form.id, this.form)
        .then((response) => this.processSaved())
        .catch((error) => this.$loading.finish());
    },

    processSaved() {
      this.$emit("store");
      this.dialog = false;
      this.$loading.finish();
    },
  },
};
</script>

<style>
</style>