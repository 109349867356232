<template>
  <div>
    <v-row>
      <v-col cols="12">
        Novos Assinantes {{ newSubscriptions }} | Cancelamentos
        {{ cancellations }}
      </v-col>
    </v-row>
    <v-row class="align-center">
      <v-col cols="12" md="4" class="d-flex">
        <app-text-field
          @change="select()"
          label="Pesquisar"
          v-model="searchParams.text"
        />
        <v-btn class="ml-2" @click="select()" color="primary">
          <app-icon>search</app-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="2">
        <app-text-field
          @change="select()"
          v-model="searchParams.company_id"
          label="Company ID"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <app-select
          @change="select()"
          label="Método de Pagamento"
          :items="paymentVendors"
          clearable
          v-model="searchParams.payment_vendor"
        />
      </v-col>
      <v-col>
        <app-select
          @change="select()"
          label="Status de assinatura"
          :items="subscriptionsStatus"
          small-chips
          deletable-chips
          multiple
          v-model="searchParams.subscription_status"
        />
      </v-col>
      <v-col cols="3">
        <app-select
          @change="select()"
          :items="plans"
          v-model="searchParams.plan_id"
          :item-text="
            (item) =>
              `${item.name} - ${item.type} - ${item.status} - ${$format.moneyBr(
                item.value
              )} - ${item.vendor}`
          "
          item-value="id"
          label="Plano"
          clearable
        />
      </v-col>
      <v-col cols="3">
        <v-switch
          @change="select()"
          v-model="searchParams.subscription_overdue"
          :true-value="1"
          :false-value="0"
          hide-details
          label="Vencidos"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <app-text-field
          @change="select()"
          type="date"
          label="Data Registro Inicial"
          v-model="searchParams.created_at_start"
        />
      </v-col>
      <v-col>
        <app-text-field
          @change="select()"
          type="date"
          label="Data Registro Final"
          v-model="searchParams.created_at_end"
        />
      </v-col>
      <v-col>
        <app-text-field
          @change="select()"
          type="date"
          label="Data Visita Inicial"
          v-model="searchParams.visited_at_start"
        />
      </v-col>
      <v-col>
        <app-text-field
          @change="select()"
          type="date"
          label="Data Visita Final"
          v-model="searchParams.visited_at_end"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <app-number-field
          @change="select()"
          :precision="0"
          v-model="searchParams.visit_count_start"
          label="N° Visita Inicial"
        />
      </v-col>

      <v-col>
        <app-number-field
          @change="select()"
          :precision="0"
          v-model="searchParams.sessions_count_start"
          label="Nº Sessões Inicial"
        />
      </v-col>

      <v-col>
        <app-number-field
          @change="select()"
          :precision="0"
          v-model="searchParams.customer_count_start"
          label="Nº Clientes Inicial"
        />
      </v-col>

      <v-col>
        <v-chip-group
          @change="select()"
          mandatory
          multiple
          v-model="searchParams.company_type"
        >
          <v-chip outlined filter :value="'clinic'">clinica</v-chip>
          <v-chip filter :value="'individual'">individual</v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex">
        <v-btn @click="clearSearchParams()" color="secondary">
          limpar filtros
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <app-pagination @click="select($event)" :data="companies">
          <v-data-table
            dense
            :headers="headers"
            :items="companies.data"
            hide-default-footer
            loading="true"
            disable-sort
            :items-per-page="-1"
            @click:row="viewUserSubscriptions($event.id)"
          >
            <template v-slot:[`item.name`]="{ item }">
              <CompanyLabel class="py-2" :value="item" />
            </template>
            <template v-slot:[`item.phone_wa`]="{ item }">
              {{ $format.phone(item.owner.user.phone_wa) }}
              <WhatsAppApiBtn
                :phone="item.owner.user.phone_wa"
                color="primary"
                @click.prevent
              />
            </template>

            <template v-slot:[`item.data`]="{ item }">
              <app-icon small>history</app-icon>
              {{ $format.dateRelative(item.visited_at) }} ({{
                item.visit_count
              }}) |

              <app-icon small>person</app-icon>
              {{ item.customers_count }} |

              <app-icon small>calendar_today</app-icon>
              {{ item.sessions_count }}
            </template>
            <template v-slot:[`item.subscription`]="{ item }">
              <template v-if="item.last_subscription">
                <span>
                  {{ item.last_subscription.plan.name }} ({{
                    item.last_subscription.status
                  }}, {{ item.last_subscription.vendor }})
                </span>
                <br />
                <small
                  >Fim:
                  {{ $format.dateBr(item.last_subscription.ends_at) }}</small
                >
              </template>
              <span v-if="item.is_free_account == 1">Acesso Gratuito</span>
              <span v-if="!item.last_subscription && item.is_free_account == 0">
                Período de Teste ({{ daysToEnd(item.free_trial_ends_at) }})
                <br />
                <small>
                  Data de cadastro:
                  {{ $format.dateBr(item.created_at) }}
                </small>
              </span>
            </template>
          </v-data-table>
        </app-pagination>
      </v-col>
    </v-row>
    <CompanySubscriptionsView
      @update="select()"
      ref="CompanySubscriptionsView"
    />
  </div>
</template>

<script>
import WhatsAppApiBtn from "@/components/app/ui/WhatsAppApiBtn.vue";
import CompanyLabel from "@/components/company/sections/CompanyLabel.vue";
import CompanySubscriptionsView from "@/components/subscription/sections/CompanySubscriptionsView.vue";

import { differenceInCalendarDays, parseISO, format } from "date-fns";
export default {
  components: {
    CompanyLabel,
    WhatsAppApiBtn,
    CompanySubscriptionsView,
  },
  data() {
    return {
      paymentVendors: [
        { text: "Pagar.Me", value: "pagarme" },
        { text: "PagSeguro", value: "pagseguro" },
        { text: "PIX", value: "pix" },
        { text: "Stripe", value: "stripe" },
        { text: "Efi", value: "efi" },
      ],

      subscriptionsStatus: [
        { value: "sending", text: "sending" },
        { value: "pending_payment", text: "pending_payment" },
        { value: "waiting_payment", text: "waiting_payment" },
        { value: "processing", text: "processing" },
        { value: "paid", text: "paid" },
        { value: "past_due", text: "past_due" },
        { value: "unpaid", text: "unpaid" },
        { value: "ended", text: "ended" },
        { value: "canceled", text: "canceled" },
      ],

      headers: [
        { text: "Nome", value: "name", align: "start" },
        { text: "WhatsApp", value: "phone_wa", align: "center" },
        { text: "Stats", value: "data", align: "center" },
        { text: "Modo", value: "type", align: "center", width: "1%" },
        {
          text: "Plano",
          value: "subscription",
          align: "center",
        },
      ],

      companies: {},
      newSubscriptions: 0,
      cancellations: 0,
      searchParams: {
        text: null,
        subscriber_type: ["subscribers"],
        status: "active",
        subscription_status: null,
        subscription_overdue: 0,
        payment_vendor: null,
        created_at_start: null,
        created_at_end: null,
        visited_at_start: null,
        visited_at_end: null,
        visited_count_start: null,
        visited_count_end: null,
        customers_count_start: null,
        customers_count_end: null,
        sessions_count_start: null,
        sessions_count_end: null,
        plan_id: null,
        company_type: ["individual"],
        page: 1,
      },

      plans: [],
    };
  },

  mounted() {
    if (this.$route.query.recommender_id) {
      this.searchParams.company_id = this.$route.query.recommender_id;
    }
    this.select();
    this.getPlans();
  },

  methods: {
    select(page) {
      this.searchParams.page = page;
      this.$loading.start();
      this.$http
        .index("admin/companies", this.searchParams)
        .then((response) => {
          this.$loading.finish();
          this.companies = response.companies;
          this.newSubscriptions = response.newSubscriptions;
          this.cancellations = response.cancellations;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },

    getPlans() {
      this.$http.index("subscription/plans").then((response) => {
        this.plans = response.plans;
      });
    },

    daysToEnd(date) {
      let days = differenceInCalendarDays(parseISO(date), new Date());

      if (days < 0) {
        return "Terminou";
      }

      return "restam " + days + " dias";
    },

    getPWADisplayMode() {
      const isStandalone = window.matchMedia(
        "(display-mode: standalone)"
      ).matches;
      if (document.referrer.startsWith("android-app://")) {
        var mode = "twa";
      } else if (navigator.standalone || isStandalone) {
        var mode = "standalone";
      }
      var mode = "browser";

      let stand_alone = window.matchMedia("(display-mode: standalone)").matches;
      let navigator_sa = navigator.standalone;
      let twa = document.referrer.includes("android-app://");

      return {
        mode: mode,
        stand_alone: stand_alone,
        navigator_sa: navigator_sa,
        twa: twa,
      };
    },

    clearSearchParams() {
      this.searchParams = {
        text: null,
        subscriber_type: ["on_trial", "subscribers"],
        status: "active",
        subscription_status: null,
        payment_vendor: null,
        created_at_start: null,
        created_at_end: null,
        visited_at_start: null,
        visited_at_end: null,
        visited_count_start: null,
        visited_count_end: null,
        customers_count_start: null,
        customers_count_end: null,
        sessions_count_start: null,
        sessions_count_end: null,
        plan_id: null,
        signup_metadata: null,
        company_type: ["individual"],
        page: 1,
      };

      if (this.$route.query.recommender_id) {
        this.$router.push({ name: "ManagementIndex" });
      }
      this.select();
    },

    viewUserSubscriptions(company_id) {
      this.$refs.CompanySubscriptionsView.open(company_id);
    },
  },
};
</script>

<style></style>
