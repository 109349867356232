<template>
  <div>
    <v-row>
      <v-col class="text-end">
        <v-btn @click="handleConfigPixWebhook()" color="primary">
          Webhook Pix
        </v-btn>
      </v-col>
    </v-row>
    <v-tabs v-model="tab">
      <v-tab v-for="tab in tabs" :key="tab.name">
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <br />
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="tab in tabs" :key="tab.name">
        <component :is="tab.component" />
      </v-tab-item>
    </v-tabs-items>
    <ConfigPixWebhookDialog ref="configPixWebhookDialog" />
  </div>
</template>

<script>
import ConfigPixWebhookDialog from "@/components/admin/sections/ConfigPixWebhookDialog.vue";
import AdminPlansView from "@/components/subscription/views/AdminPlansView.vue";
import AdminSubscribersView from "@/components/subscription/views/AdminSubscribersView.vue";
import AdminDashboardView from "@/components/subscription/views/AdminDashboardView.vue";
export default {
  components: {
    ConfigPixWebhookDialog,
    AdminPlansView,
    AdminSubscribersView,
    AdminDashboardView,
  },
  data() {
    return {
      tab: null,
      tabs: [
        {
          name: "Assinantes",
          component: "AdminSubscribersView",
        },
        {
          name: "Dashboard",
          component: "AdminDashboardView",
        },
        
        {
          name: "Planos",
          component: "AdminPlansView",
        },
      ],
    };
  },

  mounted() {},

  methods: {
    select(page) {},

    handleConfigPixWebhook() {
      this.$refs.configPixWebhookDialog.open();
    },
  },
};
</script>

<style></style>
