<template>
  <v-chip v-bind="$attrs" v-if="status" :color="statuses[status].color">
    {{ statuses[status].label }}
  </v-chip>
</template>

<script>
import statuses from "@/enums/subscriptionStatuses";
export default {
  props: {
    status: {},
  },

  data() {
    return {
      statuses,
    };
  },
};
</script>

<style>
</style>