const statuses = {
  sending: { label: "Enviando", color: "secondary" },
  pending: { label: "Aguardando Pagamento", color: "warning" },
  pending_payment: { label: "Aguardando Pagamento", color: "warning" },
  waiting_payment: { label: "Aguardando Pagamento", color: "warning" },
  incomplete: { label: "Aguardando Pagamento", color: "warning" },
  processing: { label: "Processando", color: "warning" },
  paid: { label: "Pago", color: "success" },
  active: { label: "Pago", color: "success" },
  past_due: { label: "Atrasado", color: "error" },
  unpaid: { label: "Não Pago", color: "error" },
  ended: { label: "Finalizado", color: "error" },
  canceled: { label: "Cancelado", color: "error" },
};

export default statuses;
