<template>
  <div>
    <v-row>
      <v-col>
        <v-select
          v-model="searchParams.type"
          :items="dateDemission"
          label="Tipo"
          outlined
          dense
        />
      </v-col>

      <v-col>
        <app-date-picker v-model="searchParams.start_at" outlined />
      </v-col>
      <v-col>
        <app-date-picker v-model="searchParams.end_at" outlined />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="select()" color="success">text</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <apexchart
          type="line"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { startOfMonth, endOfMonth } from "date-fns";

export default {
  data() {
    return {
      searchParams: {
        start_at: startOfMonth(new Date())
          .toISOString()
          .substr(0, 10), // new Date().toISOString().substr(0, 10
        end_at: endOfMonth(new Date())
          .toISOString()
          .substr(0, 10),
        type: "day",
      },
      stats: {
        subscriptions: [],
        cancellations: [],
        signup: [],
      },

      dateDemission: [
        {
          text: "Mês",
          value: "month",
          format: "MM/YYYY",
        },
        {
          text: "Dia",
          value: "day",
          format: "DD/MM/YYYY",
        },
      ],
    };
  },

  mounted() {
    this.select();
  },

  computed: {
    series() {
      return [
        {
          name: "Assinaturas",
          type: "column",
          color: "#0EBC00",
          data: this.stats.subscriptions.map((item) => parseInt(item.total)),
        },
        {
          name: "Cancelamentos",
          type: "column",
          color: "#ff0000",
          data: this.stats.cancellations.map((item) => parseInt(item.total)),
        },
        {
          name: "Signup",
          type: "line",
          color: "#0A76B7",
          data: this.stats.signup.map((item) => parseInt(item.total)),
        },
      ];
    },
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: "line",
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [1, 1, 4],
        },
        title: {
          show: false,

          text: "Relação de Assinaturas e Cancelamentos",
          align: "left",
          offsetX: 110,
        },
        xaxis: {
          categories: this.stats.interval,
        },

        tooltip: {
          fixed: {
            enabled: true,
            position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60,
          },
        },
        legend: {
          horizontalAlign: "left",
          offsetX: 40,
        },
      };
    },
  },

  methods: {
    dateDemissionFormat(date) {},

    async select() {
      this.$loading.start();
      await this.$http
        .index("subscription/admin/dashboard", this.searchParams)
        .then((response) => {
          this.stats = response;
          this.$loading.finish();
        })
        .catch((error) => this.$loading.finish());
    },
  },
};
</script>

<style></style>
